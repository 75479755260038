body{
    /*background-color: #09182e !important;*/
    background-color: #190124 !important;
    /*color: #e5f0fe;*/
    color: #cfd0d7;
    font-family: 'Roboto', sans-serif;
}
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #190124;
}
::-webkit-scrollbar-thumb {
    background: #a94a4a;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #e38d8d;
}
@media (min-width: 768px) {
    .overlap-left{
        margin-left: -60px;
    }
}
.login-card{
    max-width: 400px !important;
}
.bg-glass{
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
}
.nav-glass{
    border: transparent !important;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    color: #a94a4a;
}
.nav-glass:hover{
    background: rgba(255, 255, 255, 0.10);
    cursor: pointer;
}
.dash-nav-link{
    margin: 10px 10px 0 10px;
    text-decoration: none;
}
.dash-nav-link:last-child{
    margin-bottom: 10px;
}
.dash-nav-link-selected{
    background: rgba(255, 255, 255, 0.10);
    color: #e38d8d !important;
}
.color-a{
    /*color: #12ffdb;*/
    color: #e38d8d;
}
.color-b{
    color: #a94a4a;
}
.bg-a{
    /*background-color: #162944;*/
    background-color: #30003c;
}
.bg-b{
    background-color: #a94a4a;
}
.background{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -999;
}
.active-page{
    background-color: rgba(102, 51, 153, 0.4);
    border-bottom: 2px solid #e38d8d;
    border-radius: 5px;
    margin: 0 5px 0 5px;
    padding: 10px;
}
.article-card{
    cursor: pointer;
    transition: transform 0.3s;
}
.article-card:hover{
    transform: scale(1.05);
}
.btn-a{
    background-color: #e38d8d !important;
    border-color: #e38d8d !important;
}
.btn-a:hover{
    background-color: #a94a4a !important;
    border-color: #e38d8d !important;
}
.btn-b{
    border-color: #e38d8d !important;
    color: #e38d8d !important;
}
.btn-b:hover{
    background-color: #a94a4a !important;
    color: #fff !important;
}
input, textarea, option, select{
    background-color: #30003c !important;
    border-color: #e38d8d !important;
    color: #fff !important;
}
.dashboard-wrapper{
    display: flex;
    flex-wrap: wrap;
}
.dashboard-sidebar {
    width: 280px;
}
.dashboard-content {
    width: calc(100% - 280px);
}
@media (max-width: 800px) {
    .dashboard-sidebar, .dashboard-content {
        flex: 100%;
    }
}
.form-control::placeholder{
    color: #afadad !important;
}
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.loader {
    margin-left: 10px;
    border: 3px solid #e38d8d;
    border-radius: 50%;
    border-top: 3px solid #a94a4a;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}
.loading-link{
    border: 0;
    outline: 0;
    background: transparent;
    color: #e38d8d !important;
    cursor: pointer;
}
.user-image-nav{
    width: 45px;
    height: 45px;
    border: 2px solid #e38d8d;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
}
.user-image-profile{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-left: -15px !important;
}
.user-image-profile-upload-btn{
    margin-left: -55px !important;
    margin-bottom: -120px !important;
    border: 5px solid #30003c !important;
    border-radius: 50%;
}
.password-change-area{
    border: 2px solid #e38d8d;
    border-radius: 5px;
    padding: 20px;
}
.nav-tabs {
    border-bottom: 2px solid #a94a4a;
}
.btn-tab{
    border: 1px solid #a94a4a !important;
    outline: 0;
    background: transparent;
    color: #e38d8d !important;
    cursor: pointer;
}
.btn-tab-active {
    background-color: #a94a4a !important;
    color: #fff !important;
}
.btn-tab:hover{
    background-color: #e38d8d !important;
    color: #fff !important;
}
.session-table{
    border-bottom: 1px solid #a94a4a;
}
.logout-modal{
    background-color: #30003c;
    border-radius: 5px;
    border: 1px solid #a94a4a;
}
.logout-modal > .modal-header{
    border-bottom: 1px solid #a94a4a;
}
.logout-modal > .modal-footer{
    border-top: 1px solid #30003c;
}
.no-text-decoration{
    text-decoration: none;
    text-underline: transparent;
}
.dropdown-a{
    background-color: #190124 !important;
    border: 1px solid #a94a4a;
    border-radius: 5px;
    padding-top: 0;
    padding-bottom: 0;
}
.dropdown-a .dropdown-item{
    color: #e38d8d !important;
}
.dropdown-a .dropdown-item:hover{
    background-color: #a94a4a !important;
    color: #fff !important;
}
.custom-pagination .page-item.active .page-link{
    background-color: #a94a4a !important;
    border-color: #a94a4a !important;
    color: #fff !important;
}
.custom-pagination .page-item.disabled .page-link{
    background-color: #e38d8d !important;
    border-color: #a94a4a !important;
    color: #a94a4a !important;
}
.custom-pagination .page-link{
    background-color: transparent !important;
    border-color: #a94a4a !important;
    color: #e38d8d !important;
}
.custom-pagination .page-link:hover{
    background-color: #a94a4a !important;
    border-color: #a94a4a !important;
    color: #fff !important;
}
.meter-box{
    height: 250px !important;
}
.add-btn {
    background-color: #a94a4a;
    border-radius: 50%;
    color: #e38d8d;
    cursor: pointer;
    transition: transform 0.3s;
}
.add-btn:hover {
    background-color: #e38d8d;
    color: #a94a4a;
    transform: scale(1.2);
}